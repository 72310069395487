import dayjs from 'dayjs'
export default {
    convertDate(timeStr) {
        return dayjs(timeStr).toDate()
    },

    getUnix(time) {
        return dayjs(time).format('YYYY-MM-DD HH:mm:ss')
    },
    //获取当前时间
    getCurrentTime() {
        return (dayjs().format('YYYY-MM-DD HH:mm:ss'))
    },
    //获取当前日期
    getCurrnetDay() {
        return (dayjs().format('YYYY-MM-DD'))
    },
    //今日是否在传入日期后
    isAftereDay(time) {
        return dayjs().isAfter(dayjs(time))
    },
    isSameDay(time) {
        return dayjs().isSame(dayjs(time))
    },

    //获取当前日期
    getCurrnetMounth() {
        return (dayjs().format('YYYYMM'))
    },

    //今日是否在传入日期前
    isBeforeDay(time) {
        return dayjs().isBefore(dayjs(time))
    },
    converDate2Min(date) {
        return (dayjs(date).format('YYYY-MM-DD HH:mm'))
    },
    converDate2Date(date) {
        return (dayjs(date).format('YYYY-MM-DD'))
    },
    converDate2Mounth(date) {
        return (dayjs(date).format('YYYY-MM'))
    },
    //添加单位数  unit添加单位  day  year mounth
    addDays(num, unit) {
        return dayjs().add(num, unit).format('YYYY-MM-DD')
    },
    //减去单位数  unit单位  day  year mounth
    subDays(num, unit) {
        return dayjs().subtract(num, unit).format('YYYY-MM-DD')
    }

}