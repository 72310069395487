<template>
    <el-select v-bind='$attrs' v-on='$listeners'>
        <el-option v-for='cc in brandDicts' :key='cc.code' :label='cc.name' :value='cc.code'></el-option>
    </el-select>
</template>

<script>
export default {
    props: {
        codeType: {
            type: String
        }
    },
    data() {
        return {
            //字典下拉框
            brandDicts: []
        };
    },

    created() {
        this.getDictItems();
    },
    methods: {
        getDictItems() {
            var _this = this;
            this.$store.dispatch('getDicItems', { tag: this.codeType }).then(res => {
                if (res != null) {
                    _this.brandDicts = res;
                }
            });
        }
    }
};
</script>

<style></style>
