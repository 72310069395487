var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.imgUrl && _vm.imgUrl.length > 0
    ? _c("el-image", {
        staticStyle: { width: "100px", height: "100px" },
        attrs: {
          src: _vm.imgUrl.startsWith(_vm.ossHost)
            ? _vm.imgUrl + _vm.parameter
            : _vm.ossHost + _vm.imgUrl + _vm.parameter,
          "preview-src-list": _vm.getFullUrl(),
          fit: "fill"
        }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }