<template>
    <div class="app-container">
        <el-header>
            <div class="filter-container">
                <el-form :model="query" :inline="true" size="small" label-width="140px">
                    <div style="padding: auto">
                        <el-form-item>
                            <dataSelect
                                style="width: 180px;"
                                class="filter-item"
                                v-model="query.countryCode"
                                :multiple="false"
                                :codeType="'countrys'"
                                :placeholder='this.$i18n.t("common.country")'
                                @change='countryChange()'
                            ></dataSelect>
                        </el-form-item>

                        <el-form-item>
                            <el-date-picker
                                v-model="valuePicker"
                                :type="dateType"
                                popper-class='noClear'
                                :popper-append-to-body='false'
                                :range-separator='$i18n.t("common.to")'
                                :start-placeholder='$i18n.t("common.Startdate")'
                                :end-placeholder='$i18n.t("common.Enddate")'
                                :clearable="false"
                            >
                            </el-date-picker>
                        </el-form-item>

                        <el-form-item>
                            <el-input v-model="query.sourceUrlName" clearable :placeholder='$i18n.t("common.Pleaseenterthesource")'
                                      style="width: 200px;margin-left: 10px" class="filter-item"
                                      oninput="value=value.replace(/[#%=&/?+]/g,'')"
                                      @keyup.enter.native="handleQuery"/>
                        </el-form-item>

                        <el-form-item>
                            <dataSelect
                                style="width: 180px;"
                                class="filter-item"
                                v-model="query.platform"
                                clearable
                                :codeType="'platforms'"
                                :placeholder='$i18n.t("common.platform")'
                            ></dataSelect>
                        </el-form-item>

                        <el-form-item>
                            <el-select v-model="query.stateType"
                                       style="width: 180px;"
                                       @change='stateTypeChange()'
                                       class="filter-item" :placeholder='$i18n.t("common.PleaseSelectTheStatisticalType")'>
                                <el-option :label='$i18n.t("common.StatisticalByDate")' value="date_state"></el-option>
                                <el-option :label='$i18n.t("common.Hourlystatistics")'  value="hour_state"></el-option>
                                <el-option :label='$i18n.t("common.StatisticalBySource")'  value="source_state"></el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item>
                            <el-button class="filter-item" icon="el-icon-search" type="primary" @click="handleQuery">  {{$i18n.t("common.search")}}
                            </el-button>
                            <el-button
                                class="filter-item"
                                icon="el-icon-search"
                                type="primary"
                                @click="cleanQuery"
                                style="margin-left: 10px"
                                size="small "
                            >
                                {{$i18n.t("common.Reset")}}
                            </el-button>

                            <el-button
                                class="filter-item"
                                icon="el-icon-download"
                                type="primary"
                                @click="handleExport"
                                style="margin-left: 10px"
                                size="small"
                                v-loading.fullscreen.lock="fullscreenLoading"
                            >
                                {{$i18n.t("common.export")}}
                            </el-button>
                        </el-form-item>
                    </div>
                </el-form>
            </div>
        </el-header>
        <el-main>
            <el-card>
                <div class="echart" id="myChart" :style="{ float: 'left', width: '100%', height: '600px' }"></div>
            </el-card>

            <el-table v-loading="listLoading" :data="list" @selection-change="selectionChange" border stripe height='660'
                      style="width: 100%;margin-top: 10px;">

                <el-table-column :label="getStateHeader()" align="left">
                    <el-table-column :label='$i18n.t("common.Date")' prop="stateDate" align="center"  v-if="stateType == 'date_state'">
                        <template slot-scope="{ row }">
                            <span>{{ row.stateDate }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :label='$i18n.t("common.time")' prop="stateHour" align="center"  v-if="stateType == 'hour_state'">
                        <template slot-scope="{ row }">
                            <span>{{  row.stateHour}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :label='$i18n.t("common.source")' prop="sourceUrlName" align="center" v-if="stateType == 'source_state'">
                        <template slot-scope="{ row }">
                            <span>{{ row.sourceUrlName }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :label='$i18n.t("common.Sales")+"(" + this.currencySymbol + ")"' prop="totalVisit" align="center" :render-header="(h, o) => renderHeader(h, o, $i18n.t('common.TheTotalOrderAmountPaidAvailableForShipmentShippedCompleted'))">
                        <template slot-scope="{ row }">
                            <span>{{ row.amt }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :label='$i18n.t("common.TotalOrders")' prop="newVisit" align="center" :render-header="(h, o) => renderHeader(h, o, $i18n.t('common.QuantityOfPaidOrderAmountAvailableForShipmentShippedCompleted'))">
                        <template slot-scope="{ row }">
                            <span>{{ row.orderNum }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :label='$i18n.t("common.AverageOrderAmount")+"(" + this.currencySymbol + ")"' prop="newVisit" align="center" :render-header="(h, o) => renderHeader(h, o, '='+$i18n.t('common.Sales')+'/'+$i18n.t('common.TotalOrders'))">
                        <template slot-scope="{ row }">
                            <span>{{ row.avgAmt }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :label='$i18n.t("common.NumbeOfUsers")' prop="orderedUserNumbers" align="center" :render-header="(h, o) => renderHeader(h, o, $i18n.t('common.UsersWhoHavePaid'))" >
                        <template slot-scope="{ row }">
                            <span>{{ row.orderedUserNumbers }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :label='$i18n.t("common.NumberOfCanceled")'  prop="canceledOrderNumbers" align="center" :render-header="(h, o) => renderHeader(h, o, $i18n.t('common.TotalNumber'))">
                        <template slot-scope="{ row }">
                            <span>{{ row.canceledOrderNumbers }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :label='$i18n.t("common.RefundAmount") + "(" + this.currencySymbol + ")"'   prop="refundAmt" align="center" :render-header="(h, o) => renderHeader(h, o, $i18n.t('common.AmountOfCanceled'))">
                        <template slot-scope="{ row }">
                            <span>{{ row.refundAmt }}</span>
                        </template>
                    </el-table-column>
                </el-table-column>

            </el-table>

        </el-main>
        <el-footer>
            <div class="block">
                <el-pagination
                    style="margin-top: 10px"
                    background
                    @current-change="handleCurrentChange"
                    @size-change='handleSizeChange'
                    :current-page="query.page"
                    :page-size="query.size"
                    layout="total, prev, pager, next, jumper, sizes"
                    :total="total"
                ></el-pagination>
            </div>
        </el-footer>
    </div>
</template>

<script>
import moment from 'moment';
import Pagination from '@/components/common/Pagination';
import DataSelect from '@/components/common/DataSelect';
import * as echarts from 'echarts';
var myChart;
export default {
    name: 'OrderState',
    components: {
        Pagination,
        DataSelect,
        echarts
    },
    data() {
        return {
            dateType: 'daterange',
            white: '\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\ ',
            list: [],
            total: 0,
            listLoading: true,
            query: {
                page: 1,
                size: 10,
                sourceUrlName: null,
                platform: null,
                countryCode: 'ES',
                stateType: 'date_state',
            },
            selectItems: [],
            valuePicker: [
                moment().add(-30, 'days').format('yyyy-MM-DD'),
                moment().add(-1, 'days').format('yyyy-MM-DD')
            ],
            state: {
                totalAmt: 0,
                totalOrderNum: 0,
                totalAvgAmt: 0,
                totalCanceledOrderNumbers: 0,
                totalRefundAmt: 0,
                totalOrderedUserNumbers: 0
            },
            stateType: 'date_state',
            fullscreenLoading: false,
            countryCurrency: [],
            currencySymbol: '€',
            eChartsOptions: {
                title: {
                    text: this.$i18n.t("common.Orderdatastatistics")
                },
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    data: [ this.$i18n.t("common.Sales")
                        , this.$i18n.t("common.TotalOrders")
                        , this.$i18n.t("common.AverageOrderAmount")
                        , this.$i18n.t("common.NumbeOfUsers")
                        , this.$i18n.t("common.NumberOfCanceled")
                        , this.$i18n.t("common.RefundAmount")]
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                toolbox: {
                    feature: {
                        saveAsImage: {
                            title : ''
                        }
                    }
                },
                xAxis: {
                    type: 'category',
                    name: this.$i18n.t("common.time"), // x轴名称
                    boundaryGap: false,
                    data: [],
                    nameTextStyle: {
                        fontWeight: 600,
                        fontSize: 18
                    }
                },
                yAxis: {
                    type: 'value'
                },
                series: [],
                data: []
            },
        };
    },
    created() {

    },
    mounted() {
        this.doQueryList({pageNo: 1});
        this.initEcharts();
        this.getDictItems();
    },
    methods: {
        /**
         * 国家切换
         * */
        countryChange(){
            this.changeCurrencySymbol()
        },
        /**
         * 图表初始化
         */
        initEcharts() {
            if (myChart != null && myChart != '' && myChart != undefined) {
                myChart.dispose(); //销毁
            }

            this.eChartsOptions.xAxis.data = [];
            this.eChartsOptions.series = [];
            var a = {
                name: this.$i18n.t("common.Sales"),
                data: [],
                stack: 'x',
                type: 'line',
                areaStyle: {}
            };
            var b = {
                name: this.$i18n.t("common.TotalOrders"),
                data: [],
                stack: 'x',
                type: 'line',
                areaStyle: {}
            };
            var c = {
                name: this.$i18n.t("common.AverageOrderAmount"),
                data: [],
                stack: 'x',
                type: 'line',
                areaStyle: {}
            };
            var d = {
                name: this.$i18n.t("common.NumbeOfUsers"),
                data: [],
                stack: 'x',
                type: 'line',
                areaStyle: {}
            };
            var e = {
                name: this.$i18n.t("common.NumberOfCanceled"),
                data: [],
                stack: 'x',
                type: 'line',
                areaStyle: {}
            };
            var f = {
                name: this.$i18n.t("common.RefundAmount"),
                data: [],
                stack: 'x',
                type: 'line',
                areaStyle: {}
            };
            this.$store.dispatch('listOrderState', this.query).then((res) => {
                this.orderData = res;
                const reverse = res.reverse()
                this.setxAxisName()
                for (var data of reverse) {
                    if(this.query.stateType === 'hour_state'){
                        this.eChartsOptions.xAxis.data.push(data.stateHour);
                    }else if(this.query.stateType === 'source_state'){
                        this.eChartsOptions.xAxis.data.push(data.sourceUrlName);
                    }else {
                        this.eChartsOptions.xAxis.data.push(data.stateDate);
                    }
                    a.data.push(data.amt);
                    b.data.push(data.orderNum);
                    c.data.push(data.avgAmt);
                    d.data.push(data.orderedUserNumbers);
                    e.data.push(data.canceledOrderNumbers);
                    f.data.push(data.refundAmt);
                }
                this.eChartsOptions.series.push(a);
                this.eChartsOptions.series.push(b);
                this.eChartsOptions.series.push(c);
                this.eChartsOptions.series.push(d);
                this.eChartsOptions.series.push(e);
                this.eChartsOptions.series.push(f);
                // 图表初始化
                myChart = echarts.init(document.getElementById('myChart'));
                myChart.setOption(this.eChartsOptions);
                //随着屏幕大小调节图表
                window.addEventListener('resize', () => {
                    myChart.resize();
                });
            });
        },
        /**
         * 根据统计类型 选择渲染
         * */
        stateTypeChange(){
            if(this.query.stateType === 'source_state' || this.query.stateType === 'date_state'){
                this.dateType = 'daterange';
                this.valuePicker = [
                    moment(this.valuePicker[0]).format('yyyy-MM-DD'),
                    moment(this.valuePicker[1]).format('yyyy-MM-DD')
                ]
            }else if(this.query.stateType === 'hour_state'){
                this.dateType = 'datetimerange';
                this.valuePicker = [
                    moment(this.valuePicker[0]).format('yyyy-MM-DD 00:00:00'),
                    moment(this.valuePicker[1]).format('yyyy-MM-DD 23:59:59')
                ]
            }
        },
        /**
         * 根据统计类型 选择渲染
         * */
        setxAxisName(){
            if(this.query.stateType === 'source_state'){
                this.eChartsOptions.xAxis.name = this.$i18n.t("common.source")
            }else if(this.query.stateType === 'hour_state'){
                this.eChartsOptions.xAxis.name = this.$i18n.t("common.time")
            }else if(this.query.stateType === 'date_state'){
                this.eChartsOptions.xAxis.name = this.$i18n.t("common.time")
            }
        },
        /**
         * 货币符号查询
         * */
        getDictItems() {
            var _this = this;
            this.$store.dispatch('getDicItems', { tag: 'country_currency' }).then((res) => {
                if (res != null) {
                    _this.countryCurrency = res;
                    this.changeCurrencySymbol();
                }
            });
        },
        /**
         * 设置货币符号
         * */
        changeCurrencySymbol(){
            if (this.query.countryCode != null && this.query.countryCode !== '') {
                for (var item of this.countryCurrency) {
                    if (this.query.countryCode === item.code) {
                        this.currencySymbol = item.name;
                    }
                }
            }
        },
        /**
         * 分页
         */
        handleCurrentChange(val) {
            this.query.page = val;
            this.doQueryList({ page: val });
            this.initEcharts()
        },
        handleSizeChange(val){
            this.query.page = 1;
            this.query.size = val;
            this.doQueryList({pageNo: this.query.page, pageSize: this.query.size});
            this.initEcharts()
        },
        /**
         * 选择框变化
         */
        selectionChange(val) {
            this.selectItems = val;
        },
        /**
         * 触发搜索操作
         */
        handleQuery() {
            this.doQueryList({pageNo: 1});
            this.initEcharts()
        },
        moment,
        /**
         * 执行列表查询
         */
        doQueryList({pageNo, pageSize}) {
            this.stateType = this.query.stateType
            // 国家校验
            if(this.query.countryCode === ""){
                this.$message({
                    type: 'warning',
                    message: this.$i18n.t("common.Countrycannotbeempty")
                });
                return;
            }

            if (pageNo) {
                this.query.page = pageNo;
            }
            if (pageSize) {
                this.query.size = pageSize;
            }
            if(this.query.stateType === 'source_state' || this.query.stateType === 'date_state'){
                this.query.beginTime = moment(this.valuePicker[0]).format('yyyy-MM-DD 00:00:00');
                this.query.endTime = moment(this.valuePicker[1]).format('yyyy-MM-DD 23:59:59');

            }

            this.listLoading = true;

            this.$store
                .dispatch('getOrderStatePage', this.query)
                .then((res) => {
                    this.setxAxisName()
                    this.list = res.list;
                    this.total = res.total;
                    if (res.list && res.list.length > 0) {
                        const orderState = res.list[0];
                        this.state = {
                            totalAmt: orderState.totalAmt,
                            totalOrderNum: orderState.totalOrderNum,
                            totalAvgAmt: orderState.totalAvgAmt,
                            totalCanceledOrderNumbers: orderState.totalCanceledOrderNumbers,
                            totalRefundAmt: orderState.totalRefundAmt,
                            totalOrderedUserNumbers: orderState.totalOrderedUserNumbers
                        }
                    } else {
                        this.state = {
                            totalAmt: 0,
                            totalOrderNum: 0,
                            totalAvgAmt: 0,
                            totalCanceledOrderNumbers: 0,
                            totalRefundAmt: 0,
                            totalOrderedUserNumbers: 0
                        }
                    }
                })
                .finally(() => {
                    this.listLoading = false;
                });
        },
        /**
         * 表单重置
         */
        cleanQuery() {
                (this.dateType = 'daterange'),
                (this.query.countryCode = 'ES'),
                (this.query.sourceUrlName = null),
                (this.query.platform = null),
                (this.query.stateType = 'date_state'),
                (this.currencySymbol = '€'),
                (this.stateType = 'date_state'),
                (this.valuePicker = [
                    moment().add(-30, 'days').format('yyyy-MM-DD'),
                    moment().add(-1, 'days').format('yyyy-MM-DD')
                ]);
            this.doQueryList({ pageNo: 1 });
            this.initEcharts()
        },
        /**
         * 表格头部汇总
         * @returns {string}
         */
        getStateHeader() {
            return this.white + this.$i18n.t('common.Sales') + '：' + this.state.totalAmt + this.currencySymbol
                +  this.white + this.$i18n.t('common.TotalOrders') + '：' + this.state.totalOrderNum
                +  this.white + this.$i18n.t('common.AverageOrderAmount') + '：' + this.state.totalAvgAmt + this.currencySymbol
                +  this.white + this.$i18n.t('common.NumbeOfUsers') + '：' + this.state.totalOrderedUserNumbers
                +  this.white + this.$i18n.t('common.NumberOfCanceled') + '：' + this.state.totalCanceledOrderNumbers
                +  this.white + this.$i18n.t('common.RefundAmount') + '：' + this.state.totalRefundAmt + this.currencySymbol;
        },
        renderHeader(h, { column, $index }, content) {
            return [column.label,h(
                'el-tooltip',
                {
                    props: {
                        content: content,
                        placement: 'top'
                    },
                },
                [h('span', { class: {'el-icon-question': true} })]
            )]
        },
        /**
         * 导出excel
         */
        handleExport() {
            this.query.fileName = this.getFileName()
            this.$confirm(this.$i18n.t("common.Areyousuretoexportthefile"), this.$i18n.t("common.prompt"), {
                type: 'warning'
            })
                .then(() => {
                    this.fullscreenLoading = true;
                    this.$store.dispatch('exportOrderStateExcel', this.query).then(res => {
                        this.fullscreenLoading = false;
                        this.$message({
                            type: 'success',
                            message: this.$i18n.t("common.Exportsuccessful")
                        });
                    });
                })
                .catch(() => {
                    this.fullscreenLoading = false;
                });
        },
        /**
         * 获取文件名称
         */
        getFileName() {
            let fileName = this.$i18n.t("common.OrderStatistics");

            if (this.query.stateType == 'date_state') {
                fileName += '-'+this.$i18n.t("common.StatisticalByDate");
            }else if (this.query.stateType == 'hour_state') {
                fileName += '-'+this.$i18n.t("common.Hourlystatistics");
            }else {
                fileName += '-'+this.$i18n.t("common.StatisticalBySource");
            }

            if (this.valuePicker && this.valuePicker.length > 0) {
                const beginTimeStr = moment(this.valuePicker[0]).format('yyyy-MM-DD');
                const endTimeStr = moment(this.valuePicker[1]).format('yyyy-MM-DD');
                fileName += '-' + beginTimeStr + '-' + endTimeStr;
            }
            fileName += '.xlsx';
            return fileName;
        }
    }
};
</script>

<style scoped>
.filter-container {
    padding: auto;
    margin-left: 20px;
    padding-top: 20px;
}

.filter-item {
    margin-right: 10px;
}
</style>
