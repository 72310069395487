var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-select",
    _vm._g(_vm._b({}, "el-select", _vm.$attrs, false), _vm.$listeners),
    _vm._l(_vm.brandDicts, function(cc) {
      return _c("el-option", {
        key: cc.code,
        attrs: { label: cc.name, value: cc.code }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }