var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("el-header", [
        _c(
          "div",
          { staticClass: "filter-container" },
          [
            _c(
              "el-form",
              {
                attrs: {
                  model: _vm.query,
                  inline: true,
                  size: "small",
                  "label-width": "140px"
                }
              },
              [
                _c(
                  "div",
                  { staticStyle: { padding: "auto" } },
                  [
                    _c(
                      "el-form-item",
                      [
                        _c("dataSelect", {
                          staticClass: "filter-item",
                          staticStyle: { width: "180px" },
                          attrs: {
                            multiple: false,
                            codeType: "countrys",
                            placeholder: this.$i18n.t("common.country")
                          },
                          on: {
                            change: function($event) {
                              return _vm.countryChange()
                            }
                          },
                          model: {
                            value: _vm.query.countryCode,
                            callback: function($$v) {
                              _vm.$set(_vm.query, "countryCode", $$v)
                            },
                            expression: "query.countryCode"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: _vm.dateType,
                            "popper-class": "noClear",
                            "popper-append-to-body": false,
                            "range-separator": _vm.$i18n.t("common.to"),
                            "start-placeholder": _vm.$i18n.t(
                              "common.Startdate"
                            ),
                            "end-placeholder": _vm.$i18n.t("common.Enddate"),
                            clearable: false
                          },
                          model: {
                            value: _vm.valuePicker,
                            callback: function($$v) {
                              _vm.valuePicker = $$v
                            },
                            expression: "valuePicker"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      [
                        _c("el-input", {
                          staticClass: "filter-item",
                          staticStyle: {
                            width: "200px",
                            "margin-left": "10px"
                          },
                          attrs: {
                            clearable: "",
                            placeholder: _vm.$i18n.t(
                              "common.Pleaseenterthesource"
                            ),
                            oninput: "value=value.replace(/[#%=&/?+]/g,'')"
                          },
                          nativeOn: {
                            keyup: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.handleQuery.apply(null, arguments)
                            }
                          },
                          model: {
                            value: _vm.query.sourceUrlName,
                            callback: function($$v) {
                              _vm.$set(_vm.query, "sourceUrlName", $$v)
                            },
                            expression: "query.sourceUrlName"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      [
                        _c("dataSelect", {
                          staticClass: "filter-item",
                          staticStyle: { width: "180px" },
                          attrs: {
                            clearable: "",
                            codeType: "platforms",
                            placeholder: _vm.$i18n.t("common.platform")
                          },
                          model: {
                            value: _vm.query.platform,
                            callback: function($$v) {
                              _vm.$set(_vm.query, "platform", $$v)
                            },
                            expression: "query.platform"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "filter-item",
                            staticStyle: { width: "180px" },
                            attrs: {
                              placeholder: _vm.$i18n.t(
                                "common.PleaseSelectTheStatisticalType"
                              )
                            },
                            on: {
                              change: function($event) {
                                return _vm.stateTypeChange()
                              }
                            },
                            model: {
                              value: _vm.query.stateType,
                              callback: function($$v) {
                                _vm.$set(_vm.query, "stateType", $$v)
                              },
                              expression: "query.stateType"
                            }
                          },
                          [
                            _c("el-option", {
                              attrs: {
                                label: _vm.$i18n.t("common.StatisticalByDate"),
                                value: "date_state"
                              }
                            }),
                            _c("el-option", {
                              attrs: {
                                label: _vm.$i18n.t("common.Hourlystatistics"),
                                value: "hour_state"
                              }
                            }),
                            _c("el-option", {
                              attrs: {
                                label: _vm.$i18n.t(
                                  "common.StatisticalBySource"
                                ),
                                value: "source_state"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "filter-item",
                            attrs: { icon: "el-icon-search", type: "primary" },
                            on: { click: _vm.handleQuery }
                          },
                          [
                            _vm._v(
                              "  " +
                                _vm._s(_vm.$i18n.t("common.search")) +
                                "\n                        "
                            )
                          ]
                        ),
                        _c(
                          "el-button",
                          {
                            staticClass: "filter-item",
                            staticStyle: { "margin-left": "10px" },
                            attrs: {
                              icon: "el-icon-search",
                              type: "primary",
                              size: "small "
                            },
                            on: { click: _vm.cleanQuery }
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm.$i18n.t("common.Reset")) +
                                "\n                        "
                            )
                          ]
                        ),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading.fullscreen.lock",
                                value: _vm.fullscreenLoading,
                                expression: "fullscreenLoading",
                                modifiers: { fullscreen: true, lock: true }
                              }
                            ],
                            staticClass: "filter-item",
                            staticStyle: { "margin-left": "10px" },
                            attrs: {
                              icon: "el-icon-download",
                              type: "primary",
                              size: "small"
                            },
                            on: { click: _vm.handleExport }
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm.$i18n.t("common.export")) +
                                "\n                        "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            )
          ],
          1
        )
      ]),
      _c(
        "el-main",
        [
          _c("el-card", [
            _c("div", {
              staticClass: "echart",
              style: { float: "left", width: "100%", height: "600px" },
              attrs: { id: "myChart" }
            })
          ]),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading"
                }
              ],
              staticStyle: { width: "100%", "margin-top": "10px" },
              attrs: { data: _vm.list, border: "", stripe: "", height: "660" },
              on: { "selection-change": _vm.selectionChange }
            },
            [
              _c(
                "el-table-column",
                { attrs: { label: _vm.getStateHeader(), align: "left" } },
                [
                  _vm.stateType == "date_state"
                    ? _c("el-table-column", {
                        attrs: {
                          label: _vm.$i18n.t("common.Date"),
                          prop: "stateDate",
                          align: "center"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  _c("span", [_vm._v(_vm._s(row.stateDate))])
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          426780455
                        )
                      })
                    : _vm._e(),
                  _vm.stateType == "hour_state"
                    ? _c("el-table-column", {
                        attrs: {
                          label: _vm.$i18n.t("common.time"),
                          prop: "stateHour",
                          align: "center"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  _c("span", [_vm._v(_vm._s(row.stateHour))])
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          4214256467
                        )
                      })
                    : _vm._e(),
                  _vm.stateType == "source_state"
                    ? _c("el-table-column", {
                        attrs: {
                          label: _vm.$i18n.t("common.source"),
                          prop: "sourceUrlName",
                          align: "center"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  _c("span", [
                                    _vm._v(_vm._s(row.sourceUrlName))
                                  ])
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          488196917
                        )
                      })
                    : _vm._e(),
                  _c("el-table-column", {
                    attrs: {
                      label:
                        _vm.$i18n.t("common.Sales") +
                        "(" +
                        this.currencySymbol +
                        ")",
                      prop: "totalVisit",
                      align: "center",
                      "render-header": function(h, o) {
                        return _vm.renderHeader(
                          h,
                          o,
                          _vm.$i18n.t(
                            "common.TheTotalOrderAmountPaidAvailableForShipmentShippedCompleted"
                          )
                        )
                      }
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [_c("span", [_vm._v(_vm._s(row.amt))])]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$i18n.t("common.TotalOrders"),
                      prop: "newVisit",
                      align: "center",
                      "render-header": function(h, o) {
                        return _vm.renderHeader(
                          h,
                          o,
                          _vm.$i18n.t(
                            "common.QuantityOfPaidOrderAmountAvailableForShipmentShippedCompleted"
                          )
                        )
                      }
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [_c("span", [_vm._v(_vm._s(row.orderNum))])]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label:
                        _vm.$i18n.t("common.AverageOrderAmount") +
                        "(" +
                        this.currencySymbol +
                        ")",
                      prop: "newVisit",
                      align: "center",
                      "render-header": function(h, o) {
                        return _vm.renderHeader(
                          h,
                          o,
                          "=" +
                            _vm.$i18n.t("common.Sales") +
                            "/" +
                            _vm.$i18n.t("common.TotalOrders")
                        )
                      }
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [_c("span", [_vm._v(_vm._s(row.avgAmt))])]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$i18n.t("common.NumbeOfUsers"),
                      prop: "orderedUserNumbers",
                      align: "center",
                      "render-header": function(h, o) {
                        return _vm.renderHeader(
                          h,
                          o,
                          _vm.$i18n.t("common.UsersWhoHavePaid")
                        )
                      }
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _c("span", [_vm._v(_vm._s(row.orderedUserNumbers))])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$i18n.t("common.NumberOfCanceled"),
                      prop: "canceledOrderNumbers",
                      align: "center",
                      "render-header": function(h, o) {
                        return _vm.renderHeader(
                          h,
                          o,
                          _vm.$i18n.t("common.TotalNumber")
                        )
                      }
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _c("span", [
                              _vm._v(_vm._s(row.canceledOrderNumbers))
                            ])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label:
                        _vm.$i18n.t("common.RefundAmount") +
                        "(" +
                        this.currencySymbol +
                        ")",
                      prop: "refundAmt",
                      align: "center",
                      "render-header": function(h, o) {
                        return _vm.renderHeader(
                          h,
                          o,
                          _vm.$i18n.t("common.AmountOfCanceled")
                        )
                      }
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [_c("span", [_vm._v(_vm._s(row.refundAmt))])]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("el-footer", [
        _c(
          "div",
          { staticClass: "block" },
          [
            _c("el-pagination", {
              staticStyle: { "margin-top": "10px" },
              attrs: {
                background: "",
                "current-page": _vm.query.page,
                "page-size": _vm.query.size,
                layout: "total, prev, pager, next, jumper, sizes",
                total: _vm.total
              },
              on: {
                "current-change": _vm.handleCurrentChange,
                "size-change": _vm.handleSizeChange
              }
            })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }