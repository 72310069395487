<template> 
    <div>
        
        <el-form :model="formdata" :rules="rules" ref="formdata">

            <el-form-item :label='$i18n.t("common.PreSaleItems")' prop="goodsId" :label-width="formLabelWidth"  >
                <el-input v-model="formdata.barCode"  style="width: 180px;"  :placeholder="$t('common.Barcode')" disabled ></el-input>   
                <el-button style="margin-left: 10px;" type="primary"  :disabled="this.formdata.preSaleStatus == 'UnderWay' || disabledView" @click="changeGoods">{{$t('common.Selectproduct')}}</el-button>
            </el-form-item>
            

            <el-form-item :label='$i18n.t("common.PreSaleCountries")' prop="countryCode" :label-width="formLabelWidth"  >
                <el-select v-model="formdata.countryCode" clearable class="filter-item" style="width: 180px; " :placeholder='$i18n.t("common.Pleaseselectacountry")'
                    :disabled="this.formdata.preSaleStatus == 'UnderWay' || disabledView " @change="changeCountry">
                    <el-option v-for="item in dictionary.country" :key="item.id" :label="item.name" :value="item.code" :disabled="item.disabled">
                    </el-option>      
                </el-select>
            </el-form-item>


            <el-form-item  :label='$i18n.t("common.Activitytime")' prop="time" :label-width="formLabelWidth"   >   
                
                <el-date-picker v-model="formdata.preSaleStartTime" clearable  type="datetime" 
                    @change="handleTime()"
                    :disabled="this.formdata.preSaleStatus == 'UnderWay' || disabledView"
                    :placeholder="$i18n.t('common.Startdate')"  
                      >                   
                </el-date-picker>
                <span> - </span>
                <el-date-picker v-model="formdata.preSaleEndTime" clearable  type="datetime" 
                    @change="handleTime()"
                    :disabled="disabledView"
                    :picker-options="pickerOptions" 
                    :placeholder="$i18n.t('common.Enddate')" >                   
                </el-date-picker>

            </el-form-item>


            <el-form-item :label='$i18n.t("common.PresalePrice")' prop="preSaleValue" :label-width="formLabelWidth" >
                <el-radio-group v-model="formdata.preSaleDiscountType" :disabled="disabledView" >
                    <el-radio label="Discount">{{ $i18n.t("common.discount") }}</el-radio>
                    <el-radio label="Price">{{ $i18n.t("common.price") }}</el-radio>
                </el-radio-group>
                <el-input v-model="formdata.preSaleValue"  style="width: 150px;margin-left: 10px;" :disabled="disabledView" :placeholder="$t('common.PleaseEnter')"  @blur="handlePreSaleValue()" ></el-input>
                

                <span style="width: 120px;margin-left: 10px;">{{$t('common.PreSalePrice')}}:{{ formdata.preSalePrice }}</span>                      
                <span style="width: 120px;margin-left: 10px;">{{$t('common.FixedPrice')}}:<span style="margin-left: 10px;">{{ Number(formdata.fixedPrice).toFixed(2) }} </span></span>
                <span style="width: 120px;margin-left: 10px;">{{ $i18n.t("common.price1") }}:<span style="margin-left: 10px;">{{ Number(formdata.salePriceEbp).toFixed(2) }} </span></span>
                <span style="width: 120px;margin-left: 10px;">{{ $i18n.t("common.fixedprice") }}:<span style="margin-left: 10px;">{{ Number(formdata.resultPrice).toFixed(2) }} </span></span>
            
            </el-form-item>

 
 
            <el-row :gutter="20">

                <el-col :span="10">
                    <el-form-item :label='$i18n.t("common.PresaleQuantity")' prop="preSaleQuantity" :label-width="formLabelWidth">
                        <el-input v-model="formdata.preSaleQuantity" :disabled="disabledView" style="width: 150px;"  :placeholder="$t('common.PleaseEnter')"  @blur="handlePreSaleQuantity()"></el-input>
                    </el-form-item> 
                </el-col>
                <el-col :span="8">
                    <el-form-item :label='$i18n.t("common.VirtualQuantity")' label-width="100px" >
                        <el-tooltip content="预售活动时间至少大于2天，活动数量大于50 才可以设置虚拟活动数量功能" placement="top">
                            <i class="el-icon-warning"></i>
                        </el-tooltip>
                        
                        <el-input v-model="formdata.virtualQty" :disabled="disabledView || virtualQtyDisabled" style="width: 150px;margin-left: 10px;"  :placeholder="$t('common.PleaseEnter')" 
                        oninput="value=value.replace(/[^0-9]/g,'');if(value && value.length>9)value=value.slice(0,9)"
                        @input="calVirtualSales"
                        ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item :label='$i18n.t("common.VirtualSalesVolume")' label-width="80px">
                        <span>{{  formdata.virtualSalesVolume }}</span>
                    </el-form-item>
                </el-col>
            
            </el-row>



            <el-form-item  :label='$i18n.t("common.PreSaleDeposit")' prop="depositType" :label-width="formLabelWidth">
                <el-radio-group v-model="formdata.depositType" :disabled="this.formdata.preSaleStatus == 'UnderWay' || disabledView"  @input="(val) => handleDepositType(val)">
                    <el-radio label="DEPOSIT_AND_FINAL_PAYMENT">{{$t('common.ByProportion')}}</el-radio>
                    <el-radio label="FULL_PAYMENT">{{$t('common.ByFullPayment')}}</el-radio>
                </el-radio-group>
                <span v-if="formdata.depositType == 'DEPOSIT_AND_FINAL_PAYMENT'">
                    <el-input  v-model="formdata.depositValue" :disabled="this.formdata.preSaleStatus == 'UnderWay' || disabledView"  style="width: 150px;margin-left: 10px;"  :placeholder="$t('common.PleaseEnter')" @blur="handleDepositValue()" ></el-input>%
                </span>
                

            </el-form-item>
 
            <el-form-item :label="$t('common.AssociatedAGBSOnthewayOrders')"  prop="agbsInTransitOrderNo" :label-width="formLabelWidth">
                <el-input v-model="formdata.agbsInTransitOrderNo" :disabled="disabledView" style="width: 300px;"  :placeholder="$t('common.PleaseEnterTheDocumentCode')" ></el-input>
            </el-form-item>


            <el-form-item :label="$t('common.EstimatedDeliveryTime')" prop="expectedShipmentTime" :label-width="formLabelWidth">
                
                <el-date-picker v-model="formdata.expectedShipmentTime" :disabled="disabledView"  type="datetime"  :placeholder="$t('common.SelectTime')"></el-date-picker>
        
            </el-form-item>

            <el-form-item :label="$t('common.FinalPaymentDeadline')" prop="finalPaymentDeadlineTime" :label-width="formLabelWidth" >                       
                <el-date-picker v-model="formdata.finalPaymentDeadlineTime" type="datetime"  :disabled="formdata.finalPaymentDeadlineTimeDisabled || disabledView" :placeholder="$t('common.SelectTime')"></el-date-picker>
            </el-form-item>
 

            <el-form-item :label="$t('common.PreSaleShippingFee')" prop="freightFeeType" :label-width="formLabelWidth" >     

                <el-radio-group v-model="formdata.freightFeeType" :disabled="disabledView"  @input="(val) => handleFreightFeeType(val)" >
                    <el-radio label="Fixed">{{$t('common.FixedShippingFee')}}</el-radio>
                    <el-radio label="Condition">{{$t('common.FreeShippingSubjectToConditions')}}</el-radio>
                </el-radio-group>

                <span style="width: 100px;margin-left: 10px;">{{$t('common.Basicfreight')}}</span> 
                <el-input v-model="formdata.freightFee" :disabled="disabledView"  style="width: 150px;margin-left: 10px;" :placeholder="$t('common.Pleaseentertheshippingcost')"   ></el-input>
                <span v-if="formdata.freightFeeType == 'Condition'" style="width: 100px;margin-left: 10px;">{{$t('common.OrderPrice')}} ≥</span> 
                <el-input v-if="formdata.freightFeeType == 'Condition'" :disabled="disabledView"  v-model="formdata.freeFreightFeePrice"   style="width: 150px;margin-left: 10px;" :placeholder="$t('common.PleaseEnter')"  ></el-input>
                

            </el-form-item>
 

        </el-form>   
        

        <span slot="footer" class="dialog-footer">
			<el-button style="width: 20%;" v-if = "operationType != 'view'" @click="closePreSaleDialog(false)">{{$t('common.cancel')}}</el-button>
			<el-button style="width: 20%;" v-if = "operationType != 'view'" type="primary" :loading="loading"  @click="onSubmit">{{$t('common.confirm1')}}</el-button>
            <el-button style="width: 20%;" v-if = "operationType == 'view'" @click="closePreSaleDialog(false)">{{$t('common.close')}}</el-button>
		</span>
        

        <el-dialog :visible.sync="goodsDialogVisible" v-if="goodsDialogVisible"  width="1200px"  append-to-body @close="closeGooodsDialog"   >
            <goods-dialog  ref="goodsDialog"  :defaultDictionary="defaultDictionary"   @handleSelectGoods="handleSelectGoods"  />
        </el-dialog>

    </div>
</template>


<script>
 
 import goodsDialog from './GoodsDialog.vue';

export default {
    components: {  
        goodsDialog
    },
    props: {
        defaultDictionary: {
            type: Object,
            default: () => {
                return {};
            }
        },
        isEdit: {
            type: Boolean,
            default: false
        },
        operationType: {
            type: String,
            default: ''
        },
        formItem: {
            type: Object,
            default: () => {
                return {};
            }
        },
        defaultFormItem: {
            type: Object,
            default: () => {
                return {};
            }
        }
    },
    data() {
        // 预售价格
        var validatePreSaleValue = (rule, value, callback) => {
            if(this.formdata.preSaleDiscountType == null){  
                return callback(new Error(this.$i18n.t("common.PresalePriceTypeCannotBeEmpty")));  
            } 
            if(this.formdata.preSaleValue == null){
                return callback(new Error(this.$i18n.t("common.PresalePriceCannotBeEmpty"))); 
            }
            // var pattern = /^(([1-9]{1}\d*))(\.(\d){0,2})?$/;
            var pattern = /^(([1-9]{1}\d*)|([0]{1}))(\.(\d){1,2})?$/;
            if (!pattern.test(this.formdata.preSaleValue)) {
                return callback(new Error(this.$i18n.t("common.OnlyNumbersGreaterThan0CanBeEnteredUpToTwoDecimalPlaces")));  
            }   
            if (this.formdata.preSaleValue<=0) {
                return callback(new Error(this.$i18n.t("common.OnlyNumbersGreaterThan0CanBeEnteredUpToTwoDecimalPlaces"))); 
            }
            return callback();
        };
        // 预售数量
        var validatePreSaleQuantity = (rule, value, callback) => {
        
            if(this.formdata.preSaleQuantity == null){
                return callback(new Error(this.$i18n.t("common.PresaleQuantityCannotBeEmpty")));         
            }
            var pattern = /^([1-9]{1}\d*)$/;
            if (!pattern.test(this.formdata.preSaleQuantity)) {
                return callback(new Error(this.$i18n.t("common.OnlyPositiveIntegersCanBeEnteredCannotStartWith0")));  
            } 
            return callback();
        };
        // 定金比例
        var validateDepositType = (rule, value, callback) => {
            if(this.formdata.depositType == null) {
                return callback(new Error(this.$i18n.t("common.DepositRatioTypeCannotBeEmpty")));  
            }
            if(this.formdata.depositType != null && this.formdata.depositType == 'FULL_PAYMENT'){
                this.formdata.depositValue = 100;        
            }

            if(this.formdata.depositValue == null && this.formdata.depositType != 'FULL_PAYMENT') {
                return callback(new Error(this.$i18n.t("common.DepositRatioCannotBeEmpty")));                  
            }

            if(this.formdata.depositType != null && this.formdata.depositType != 'FULL_PAYMENT'){
                var pattern = /^(\d*)$/;
                if(!pattern.test(this.formdata.depositValue)){
                    return callback(new Error(this.$i18n.t("common.OnlyIntegersBetween0And100CanBeEnteredExcluding0And100")));              
                }
                if(this.formdata.depositValue <= 0){
                    this.formdata.depositValue =1
                }
                if(this.formdata.depositValue >= 100){
                    this.formdata.depositValue =99
                }              
            }  
            return callback();
        };
        // 尾款支付截止时间
        var validateFinalPaymentDeadlineTime = (rule, value, callback) => {

            if(this.formdata.finalPaymentDeadlineTime == null){
                return callback(new Error(this.$i18n.t("common.FinalPaymentDeadlineCannotBeEmpty")));  
            }
            
            let finalPaymentDeadlineDate = new Date(this.formdata.finalPaymentDeadlineTime).getTime();

            let preSaleEndTimeDate = new Date(this.formdata.preSaleEndTime).getTime();
            
            if( finalPaymentDeadlineDate - preSaleEndTimeDate < 1000 * 3600 * 5){
                return callback(new Error(this.$i18n.t("common.FinalPaymentShouldBeDoneBefore5HoursBeforeTheEndOfPresalePromotion")));  
            }
            return callback();
        };
        // 预售运费
        var validateFreightFeeType = (rule, value, callback) => {
            // console.log('validateFreightFeeType',this.formdata.freightFee,this.formdata.freightFee == '')
            if(this.formdata.freightFee == null){
                return callback(new Error(this.$i18n.t("common.Pleaseentertheshippingcost")));  
            }

            var pattern = /^(([1-9]{1}\d*)|([0]{1}))(\.(\d){1,2})?$/;
            if (!pattern.test(this.formdata.freightFee)) {
                return callback(new Error("只能输入不小于0的数，最多两位小数"));  
            }   

            if(this.formdata.freightFeeType != null && this.formdata.freightFeeType == 'Condition'){
                var pattern = /^(([1-9]{1}\d*)|([0]{1}))(\.(\d){1,2})?$/;
                if (!pattern.test(this.formdata.freeFreightFeePrice)) {
                    return callback(new Error("只能输入不小于0的数，最多两位小数"));  
                }  
            }          
            return callback();
        };
        // 预售活动时间
        var validateTime = (rule, value, callback) => {

            if(this.formdata.preSaleStartTime == null || this.formdata.preSaleStartTime == ''){
                return callback(new Error(this.$i18n.t("common.Pleaseselectastartdate")));  
            }
            
            if(this.formdata.preSaleEndTime == null || this.formdata.preSaleEndTime == ''){
                return callback(new Error(this.$i18n.t("common.Pleaseselecttheenddate")));  
            }
            
            let startTime=   new Date(this.formdata.preSaleStartTime).getTime();
            let endTime =new Date(this.formdata.preSaleEndTime).getTime();
            if(startTime>endTime){
                return callback(new Error(this.$i18n.t("common.TheEndTimeNeedsToBeLaterThanTheStartTime")));  
            }
            return callback();
        };
        return {
            // 预售结束时间 只能选择当前时间之后
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now(); 
                }
            },
            // 虚拟数量禁用条件 预售活动时间至少大于2天，活动数量大于50 才可以设置虚拟活动数量功能
            virtualQtyDisabled: true,
            formLabelWidth: '200px',
            loading: false,
            goodsDialogVisible: false,
            // 查看 所有输入框禁用
            disabledView: 'view' == this.operationType,
            priceMap: new Map(),
            dictionary: JSON.parse(JSON.stringify(this.defaultDictionary)),
            formdata: JSON.parse(JSON.stringify(this.formItem)),
            rules: {
                goodsId: [ 
                    { required: true, message: this.$i18n.t("common.Pleaseselectaproduct"), trigger: 'blur' },              
                ],
                countryCode: [
                    { required: true, message:  this.$i18n.t("common.Pleaseselectacountry"), trigger: 'change' },
                ],
                time: [
                    { required: true, validator: validateTime, trigger: 'change' }, 
                    // { 
                    //     type: 'array', required: true, message:  this.$i18n.t("common.PresaleTimeCannotBeEmpty"), trigger: 'change',
                    //     fields: {
                    //         0: { type: 'string', required: true, message:  this.$i18n.t("common.Pleaseselectastartdate") },
                    //         1: { type: 'string', required: true, message: this.$i18n.t("common.Pleaseselecttheenddate") },
                    //     }
                    // }
                ],
                preSaleValue: [
                    { required: true, message: this.$i18n.t("common.PresalePriceCannotBeEmpty"), trigger: 'blur' }, 
                    { validator: validatePreSaleValue,   trigger: 'blur' }, 
                ], 
                preSaleQuantity: [
                    { required: true, message: this.$i18n.t("common.PresaleQuantityCannotBeEmpty"), trigger: 'blur' }, 
                    { validator: validatePreSaleQuantity, trigger: 'blur' }, 
                ], 
                depositType: [
                    { required: true, validator: validateDepositType, trigger: 'change' }, 
                ], 
                expectedShipmentTime: [
                    { required: true, message:  this.$i18n.t("common.EstimatedDeliveryTimeCannotBeEmpty"), trigger: 'change' },
                ],
                finalPaymentDeadlineTime: [
                    { required: true, validator: validateFinalPaymentDeadlineTime, trigger: 'change' }, 
                ],
                freightFeeType: [
                    { required: true, validator: validateFreightFeeType, trigger: 'blur' }, 
                ],
                    
            } 
        };
    },
    mounted() { 
        this.initData()
    },
    methods: {
        initData() {    
            // 重置国家禁用
            this.dictionary = JSON.parse(JSON.stringify(this.defaultDictionary));
            this.dictionary.country.forEach(item=>{
                item.disabled = true;              
            })
            if(this.isEdit || this.formdata.goodsId){
                let goodsId = [];
                goodsId.push(this.formdata.goodsId);
                this.$store.dispatch('postGoodsIdPresalesConfig', goodsId).then((res) => {      
                  
                    let dataMap = new Map();
                    res.forEach((item)=>{
                        dataMap.set(item.goodsId,item)
                    })
                    
                    let goodsInfo = dataMap.get(this.formdata.goodsId);
                    this.formdata.barCode = goodsInfo.barCode


                    // 重置国家禁用
                    this.dictionary = JSON.parse(JSON.stringify(this.defaultDictionary));
                    this.dictionary.country.forEach(item=>{
                        item.disabled = true;
                        goodsInfo.goodsSpecials.forEach(item2=>{
                            if(item.code == item2.countryCode && item2.shelf){
                                item.disabled = !item2.shelf
                            }
                        })
                    })

                    this.priceMap = new Map();
                    goodsInfo.prices.forEach((item)=>{
                        this.priceMap.set(item.countryCode,item)
                    })
                    this.handleCountryPrice()
                   
                    // let type='yyyy-MM-dd hh:mm:ss'      
                    // let beginTime=this.$options.filters['formatDate'](this.formdata.preSaleStartTime,type)
                    // let endTime=this.$options.filters['formatDate'](this.formdata.preSaleEndTime,type)
                    // this.formdata.time = [];
                    // this.formdata.time.push(beginTime);
                    // this.formdata.time.push(endTime);  
                    this.formdata.preSalePrice = this.calculatePreSalePrice(this.formdata);
                    // 计算虚拟销量
                    this.calVirtualSales()
                });
            }     
        },
        //选择商品弹窗
        changeGoods() {
            this.goodsDialogVisible = true;
        },
        // 关闭选择商品列表弹窗
        closeGooodsDialog() {
            this.goodsDialogVisible = false
        },
        // 选中商品
        handleSelectGoods(row) {         
            
            if(this.formdata.goodsId != null && this.formdata.goodsId != '' && this.formdata.goodsId != row.goodsId ){
               
                this.formdata = JSON.parse(JSON.stringify(this.defaultFormItem));
            }
            console.log('handleSelectGoods',row)
            this.formdata.barCode = row.barCode
            this.formdata.goodsId = row.goodsId

            // 重置国家禁用
            this.dictionary = JSON.parse(JSON.stringify(this.defaultDictionary));
            this.dictionary.country.forEach(item=>{
                item.disabled = true;
                row.goodsSpecials.forEach(item2=>{
                    if(item.code == item2.countryCode && item2.shelf){
                        item.disabled = !item2.shelf
                    }
                })
            })

            this.priceMap = new Map();
            row.prices.forEach((item)=>{
                this.priceMap.set(item.countryCode,item)
            })

            this.handleCountryPrice()
            this.goodsDialogVisible = false
        },
        changeCountry(val){
            this.handleCountryPrice();
        },
        // 获取商品对应国家的商品价格 并封装formdata
        handleCountryPrice(){
            if(this.formdata.countryCode == null || this.formdata.countryCode == ''){
                this.formdata.fixedPrice = 0;
                this.formdata.salePriceEbp = 0;
                this.formdata.resultPrice = 0;
            }else{ 
                let price = this.priceMap.get(this.formdata.countryCode);
                console.log('price',price,this.priceMap,this.formdata.countryCode)
                if(price != null){
                    this.formdata.fixedPrice = price.fixedPrice;
                    this.formdata.salePriceEbp = price.salePriceEbp;
                    this.formdata.resultPrice = price.resultPrice;
                }else{
                    this.formdata.fixedPrice = 0;
                    this.formdata.salePriceEbp = 0;
                    this.formdata.resultPrice = 0;
                }
            }
        },

        
        handlePreSaleValue(){        
            // 输入限制：不允许高于对应划线价，高于划线价默认等于划线价。
            this.formdata.preSalePrice = 0;
            //  var pattern = /^(([1-9]{1}\d*)|([0]{1}))(\.(\d){1,2})?$/;
            //  if (!pattern.test(this.formdata.preSaleValue)) {
            //      this.$message({
            //          message: this.$i18n.t("common.OnlyNumbersGreaterThan0CanBeEnteredUpToTwoDecimalPlaces"),
            //          type: 'warning',
            //          duration: 1000
            //      });
            //      return;
            //  }  
            //  if (this.formdata.preSaleValue<=0) {
            //      this.$message({
            //          message: this.$i18n.t("common.OnlyNumbersGreaterThan0CanBeEnteredUpToTwoDecimalPlaces"),
            //          type: 'warning',
            //          duration: 1000
            //      });
            //      return;
            //  } 
            this.$refs.formdata.validateField('preSaleValue',(errorMessage)=>{
                if(!errorMessage){
                    let price =  Number(this.getPricePriority(this.formdata)).toFixed(2);
                    if(this.formdata.preSaleDiscountType == 'Discount'){            
                        if(Number(this.formdata.preSaleValue).toFixed(2) - Number(100).toFixed(2) >0){
                            this.formdata.preSaleValue = 100;
                        }
                    } else { 
                        if(Number(this.formdata.preSaleValue).toFixed(2) - price >0){
                            this.formdata.preSaleValue = price;
                        }
                    }
                    // 计算预售价
                    this.formdata.preSalePrice = this.calculatePreSalePrice(this.formdata); 
                }               
            })         
         },  
        calculatePreSalePrice(item){
            let preSalePrice = 0;
            let price = this.getPricePriority(item);
            if(item.preSaleDiscountType == 'Discount'){
                // 计算预售价
                preSalePrice = price - price * item.preSaleValue /100;         
            } else {
                // 计算预售价
                preSalePrice = item.preSaleValue;
            }
            preSalePrice =  Number( preSalePrice ).toFixed(2);
            return preSalePrice;
        },
        getPricePriority(item){ 
            let price =  0;
            if(item.fixedPrice){
                 //固定价格
                price = item.fixedPrice;
            }else if(item.salePriceEbp){
                //售价
                price = item.salePriceEbp;
            }else if(item.resultPrice){
                //定价
                price = item.resultPrice;
            }
            return price;
        },

        handlePreSaleQuantity(){
            // var pattern = /^([1-9]{1}\d*)$/;
            // if (!pattern.test(this.formdata.preSaleQuantity)) {
            //     this.$message({
            //         message: this.$i18n.t("common.OnlyPositiveIntegersCanBeEnteredCannotStartWith0"),
            //         type: 'warning',
            //         duration: 1000
            //     });
             
            //     return false;
            // }
            this.$refs.formdata.validateField('preSaleQuantity',(errorMessage)=>{
                if(!errorMessage){
                    let salesCountA  =  this.formdata.salesCount == null ? 0:this.formdata.salesCount
                    let preSaleQuantityA  =  this.formdata.preSaleQuantity 

                    if( preSaleQuantityA < salesCountA){
                        this.formdata.preSaleQuantity = salesCountA;
                    }
                    // 计算虚拟销量
                    this.calVirtualSales();  
                }               
            })

        },

        handleDepositType(val){
            if(this.formdata.depositType == 'FULL_PAYMENT'){
                this.formdata.depositValue = 100;
            }else {
                this.formdata.depositValue = null;
            }
        },  
        handleDepositValue(){
            this.$refs.formdata.validateField('depositType',(errorMessage)=>{
                if(!errorMessage){
                    if(this.formdata.depositType != null && this.formdata.depositType != 'FULL_PAYMENT'){
                        // var pattern = /^(\d*)$/;
                        // if(!pattern.test(this.formdata.depositValue)){
                        //     this.$message({
                        //         message: this.$i18n.t("common.OnlyIntegersBetween0And100CanBeEnteredExcluding0And100"),
                        //         type: 'warning',
                        //         duration: 1000
                        //     });  
                        //     return false;
                        // }
                        if(this.formdata.depositValue <= 0){
                            this.formdata.depositValue =1
                        }
                        if(this.formdata.depositValue >= 100){
                            this.formdata.depositValue =99
                        }  
                    } 
                    if(this.formdata.depositType != null && this.formdata.depositType == 'FULL_PAYMENT'){
                        this.formdata.depositValue = 100;        
                    }
                }               
            })
        },

        handleFreightFeeType(val){
            this.formdata.freightFee = null;
            this.formdata.freeFreightFeePrice = null;
            // if(this.formdata.freightFeeType == 'Fixed'){
                
            // } 
        },  

        closePreSaleDialog(status){
            this.$emit('closePreSaleDialog',status,this.isEdit);
        },
        
        // checkForm(){
        //     let item = this.formdata
        //     // 预售时间
        //     if(item.time == null){
        //         this.$message({
        //             message: this.$i18n.t("common.PresaleTimeCannotBeEmpty"),
        //             type: 'warning',
        //             duration: 1000
        //         });
        //         this.activeName = "1";
        //         return false;
        //     }
        //     // 预售价格
        //     if(item.preSaleDiscountType == null){
        //         this.$message({
        //             message: this.$i18n.t("common.PresalePriceTypeCannotBeEmpty"),
        //             type: 'warning',
        //             duration: 1000
        //         });
        //         this.activeName = "2";
        //         return false;
        //     }
        //     if(item.preSaleValue == null){
        //         this.$message({
        //             message: this.$i18n.t("common.PresalePriceCannotBeEmpty"),
        //             type: 'warning',
        //             duration: 1000
        //         });
        //         this.activeName = "2";
        //         return false;
        //     }
        
        //     // var pattern = /^(([1-9]{1}\d*))(\.(\d){0,2})?$/;
        //     var pattern = /^(([1-9]{1}\d*)|([0]{1}))(\.(\d){1,2})?$/;
        //     if (!pattern.test(item.preSaleValue)) {
        //         this.$message({
        //             message: this.$i18n.t("common.OnlyNumbersGreaterThan0CanBeEnteredUpToTwoDecimalPlaces"),
        //             type: 'warning',
        //             duration: 1000
        //         });
        //         this.activeName = "2";
        //         return;
        //     }   
        //     if (item.preSaleValue<=0) {
        //         this.$message({
        //             message: this.$i18n.t("common.OnlyNumbersGreaterThan0CanBeEnteredUpToTwoDecimalPlaces"),
        //             type: 'warning',
        //             duration: 1000
        //         });
        //         this.activeName = "2";
        //         return;
        //     }
            

        //     // 预售数量
        //     if(item.preSaleQuantity == null){
        //         this.$message({
        //             message: this.$i18n.t("common.PresaleTimeCannotBeEmpty"),
        //             type: 'warning',
        //             duration: 1000
        //         });
        //         this.activeName = "3";
        //         return false;
        //     }
        //     var pattern = /^([1-9]{1}\d*)$/;
        //     if (!pattern.test(item.preSaleQuantity)) {
        //         this.$message({
        //             message: this.$i18n.t("common.OnlyPositiveIntegersCanBeEnteredCannotStartWith0"),
        //             type: 'warning',
        //             duration: 1000
        //         });
        //         this.activeName = "3";
        //         return false;
        //     }  

        //     // 定金比例
        //     if(item.depositType == null){
        //         this.$message({
        //             message: this.$i18n.t("common.DepositRatioTypeCannotBeEmpty"),
        //             type: 'warning',
        //             duration: 1000
        //         });
        //         this.activeName = "4";
        //         return false;
        //     }

        //     if(item.depositType != null && item.depositType == 'FULL_PAYMENT'){
        //         item.depositValue = 100;        
        //     }
            
        //     if(item.depositValue == null && item.depositType != 'FULL_PAYMENT'){
        //         this.$message({
        //             message: this.$i18n.t("common.DepositRatioCannotBeEmpty"),
        //             type: 'warning',
        //             duration: 1000
        //         });
        //         this.activeName = "4";
        //         return false;
        //     }

        //     if(item.depositType != null && item.depositType != 'FULL_PAYMENT'){
        //         var pattern = /^(\d*)$/;
        //         if(!pattern.test(item.depositValue)){
        //             this.$message({
        //                 message: this.$i18n.t("common.OnlyIntegersBetween0And100CanBeEnteredExcluding0And100"),
        //                 type: 'warning',
        //                 duration: 1000
        //             }); 
        //             this.activeName = "4"; 
        //             return false;
        //         }
        //         if(item.depositValue <= 0){
        //             item.depositValue =1
        //         }
        //         if(item.depositValue >= 100){
        //             item.depositValue =99
        //         }                
        //     }

            

        //     if(item.expectedShipmentTime == null){
        //         this.$message({
        //             message: this.$i18n.t("common.EstimatedDeliveryTimeCannotBeEmpty"),
        //             type: 'warning',
        //             duration: 1000
        //         });
        //         this.activeName = "6";
        //         return false;
        //     }

        //     if(item.finalPaymentDeadlineTime == null){
        //         this.$message({
        //             message: this.$i18n.t("common.FinalPaymentDeadlineCannotBeEmpty"),
        //             type: 'warning',
        //             duration: 1000
        //         });
        //         this.activeName = "7";
        //         return false;
        //     }
            
        //     let finalPaymentDeadlineDate = new Date(item.finalPaymentDeadlineTime).getTime();

        //     let preSaleEndTimeDate = new Date(item.preSaleEndTime).getTime();
            
        //     if( finalPaymentDeadlineDate - preSaleEndTimeDate < 1000 * 3600 * 5){
        //         this.$message({
        //             message: this.$i18n.t("common.FinalPaymentShouldBeDoneBefore5HoursBeforeTheEndOfPresalePromotion"),
        //             type: 'warning',
        //             duration: 1000
        //         });
        //         this.activeName = "7";
        //         return false;
        //     }
            
        //     return true;
        // },
        onSubmit(){
            this.$refs['formdata'].validate((valid) => {
                if (valid) {
                    this.$confirm(this.$i18n.t("common.Whethertosubmitdata"), this.$i18n.t("common.prompt"), {
                        confirmButtonText: this.$i18n.t("common.confirm"),
                        cancelButtonText: this.$i18n.t("common.cancel"),
                        type: 'warning'
                    }).then(() => {  
                        if(this.formdata.depositType != null && this.formdata.depositType == 'FULL_PAYMENT'){
                            this.formdata.depositValue = 100;        
                        }
                        if(this.formdata.depositType != null && this.formdata.depositType != 'FULL_PAYMENT'){
                            if(this.formdata.depositValue <= 0){
                                this.formdata.depositValue =1
                            }
                            if(this.formdata.depositValue >= 100){
                                this.formdata.depositValue =99
                            }                
                        }

                        // this.formdata.preSaleStartTime = new Date(this.formdata.time[0]).getTime();
                        // this.formdata.preSaleEndTime = new Date(this.formdata.time[1]).getTime();
                        this.formdata.preSaleStartTime = new Date(this.formdata.preSaleStartTime).getTime();
                        this.formdata.preSaleEndTime = new Date(this.formdata.preSaleEndTime).getTime();
                        this.formdata.expectedShipmentTime = new Date(this.formdata.expectedShipmentTime).getTime();
                        this.formdata.finalPaymentDeadlineTime = new Date(this.formdata.finalPaymentDeadlineTime).getTime();
                        

                        this.loading=true
                        if (this.isEdit) {
                            this.$store.dispatch('putPresalesConfig', this.formdata).then(response => {
                                this.$message({
                                    message: this.$i18n.t("common.Successfullymodified"),
                                    type: 'success',
                                    duration: 1000
                                });
                                this.closePreSaleDialog(true);
                            }).finally(() => {
                                this.loading = false;
                            });
                        } else {
                            this.$store.dispatch('addPresalesConfig', this.formdata).then(response => {
                                this.$message({
                                    message: this.$i18n.t("common.Successfullysubmitted"),
                                    type: 'success',
                                    duration: 1000
                                });
                                this.closePreSaleDialog(true);
                            }).finally(() => {
                                this.loading = false;
                            });
                        }

                    }); 
                    
                     
                } else {
                    // this.$message({
                    //     message:  this.$i18n.t("common.Verificationfailed"),
                    //     type: 'error',
                    //     duration: 1000
                    // });
                    return false;
                }
            });
        },
        // 判断 预售活动时间至少大于2天，活动数量大于50 才可以设置虚拟活动数量功能
        handleVirtualQtyDisabled(){
            this.virtualQtyDisabled = true;
            let virtualQty = this.formdata.virtualQty;
            this.formdata.virtualQty = null;
            if( this.formdata.preSaleStartTime != null  && this.formdata.preSaleEndTime != null && this.formdata.preSaleQuantity !=null ){
                let startTime =  new Date(this.formdata.preSaleStartTime).getTime();
                let endTime = new Date(this.formdata.preSaleEndTime).getTime();
                let preSaleQuantity = this.formdata.preSaleQuantity;
                // 活动天数
                const total = (endTime- startTime)  / (24 * 60 * 60 * 1000);
                if( total>=2 && preSaleQuantity > 50){
                    this.virtualQtyDisabled = false;  
                    this.formdata.virtualQty = virtualQty
                }
            }
        },
        // 计算虚拟销量
        calVirtualSales(){
             this.handleVirtualQtyDisabled();
                      
            if(this.formdata.preSaleStartTime == null || this.formdata.preSaleStartTime == '' ||
                this.formdata.preSaleEndTime == null ||  this.formdata.preSaleEndTime == '' ||
                this.formdata.virtualQty == null ||  this.formdata.virtualQty == '' ){
                this.formdata.virtualSalesVolume = 0 ;
                return;
            }

            let startTime =  new Date(this.formdata.preSaleStartTime).getTime();
            let endTime = new Date(this.formdata.preSaleEndTime).getTime();

            
            let preSaleQuantity = this.formdata.preSaleQuantity;
            let salesCount = this.formdata.salesCount;
            let virtualQty = this.formdata.virtualQty;
        
            const nowTime = new Date().getTime();

            //即将开始
            if (nowTime <= startTime || !virtualQty) {
                this.formdata.virtualSalesVolume = 0;
                return;
            }

            // 结束
            if (nowTime >= endTime) {
                this.formdata.virtualSalesVolume = virtualQty
                return ;
            }

            // 当实际剩余数量<=12时，释放剩余所有虚拟活动数量
            if( preSaleQuantity !=null ){
                let actualResidueSales = preSaleQuantity - salesCount
                if( actualResidueSales <=12){
                    this.formdata.virtualSalesVolume = virtualQty
                    return ;
                }
            }
           
            //过去分钟数
            const passedMinute = (nowTime - startTime) / (60 * 1000);
            console.log('passedMinute',passedMinute)

            if( passedMinute < 60 ){
                // 活动开启0-1h内: 释放10%的虚拟活动数量（剩90%）
                let virtualQtyPercent10 =  Math.floor(virtualQty / 10);
                this.formdata.virtualSalesVolume = Math.floor(virtualQtyPercent10 * passedMinute / 60);
                return ;
            }else if (passedMinute >=60 && passedMinute < 3*60){
                //   活动开启1-3h内: 释放10%的虚拟活动数量（剩80%）
                let virtualQtyPercent10 =  Math.floor(virtualQty / 10);
                let virtualQtyPercent20 =  Math.floor(virtualQty * 2 / 10);
                this.formdata.virtualSalesVolume = virtualQtyPercent10 +  Math.floor( (virtualQtyPercent20-virtualQtyPercent10) * (passedMinute - 60 ) / 120) ;
                return ;
            } else if (  passedMinute >= 3*60 && passedMinute < 24*60 ){
                //   活动开启3-24h内: 释放30%的虚拟活动数量（剩50%）
                let virtualQtyPercent20 =  Math.floor(virtualQty * 2 / 10);
                let virtualQtyPercent50 =  Math.floor(virtualQty * 5 / 10);

                this.formdata.virtualSalesVolume = virtualQtyPercent20 +  Math.floor( (virtualQtyPercent50-virtualQtyPercent20) * (passedMinute - 3*60) /  (21*60) ) ;
                return ;
            } else {
                // 活动开启24h至80%*活动时长: 按分钟维度实时计算向下取整均匀释放50%的虚拟活动数量（剩0%）
                //总分钟数 80%
                const totalMinute = (endTime- startTime) * 8 / 10 / (60 * 1000);
                if(passedMinute>=totalMinute){
                    this.formdata.virtualSalesVolume =  virtualQty;
                }else {
                    let virtualQtyPercent50 =  Math.floor(virtualQty * 5 / 10);
                    this.formdata.virtualSalesVolume =  virtualQtyPercent50 + Math.floor( (virtualQty-virtualQtyPercent50) * (passedMinute - 24*60) /  (totalMinute-24*60) ) ;
                }
               
                return ;
            } 
        },
        handleTime(){
            this.$refs.formdata.validateField('time',(errorMessage)=>{
                if(!errorMessage){
                    // 计算虚拟销量
                    this.calVirtualSales()
                }               
            })
        },
        /**
         * 时间戳转码
         */
		formatDate(row, column) {
            // 获取单元格数据
            let data = row[column.property];
            if (!data) {
                return '';
            } else {
                return moment(data).format('YYYY-MM-DD HH:mm:ss');
            }
        }
    },
    watch: {     
    }
};
</script>

<style scoped>
.dialog-footer {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>