<template>
    <div>
       
        <el-form :inline="true" @submit.native.prevent>
 
            <el-form-item>
                <el-input v-model="listQuery.idFuzzy" :placeholder='$i18n.t("common.SKU_EAN_Encoding")' clearable @keyup.enter.native="handleCurrentChange(1)"></el-input>
            </el-form-item>


            <el-form-item  >
                <el-select v-model="listQuery.shelfCountryCode"   :placeholder='$i18n.t("common.Countrieslisted")' clearable>
                    <el-option v-for="item in dictionary.country"
                        :key="item.id"
                        :label="item.name"
                        :value="item.code">
                    </el-option>      
                </el-select>		 
            </el-form-item>


            <el-form-item>
                <el-select v-model="listQuery.effectShelf" :placeholder='$i18n.t("common.Isitontheshelf")'   clearable  >
                    <el-option :label='$i18n.t("common.Listed")' value="true"></el-option>
                    <el-option :label='$i18n.t("common.Notlisted")' value="false"></el-option>
                </el-select>
            </el-form-item>

         
            <el-form-item >
                <el-button class="filter-item" icon="el-icon-search" type="primary" @click="handleCurrentChange(1)"
                            style="margin-left: 10px"> {{ $i18n.t("common.query") }}</el-button>
                <el-button class="filter-item" icon="el-icon-search" type="primary" @click="cleanQuery" style="margin-left: 10px"
                        size="small">     {{ $i18n.t("common.Reset") }}
                </el-button>
            </el-form-item>

        </el-form>  
                        

        
        <el-table ref="tableData" :data="pageData.list" style="width: 100%" height="500" border  row-key="goodsId"
            default-expand-all
            :header-cell-style="{ background: '#EAEEF9', color: '#32353a' }"
            :tree-props="{children: 'packageQtyList', hasChildren: 'hasChildren'}"
             >
 
            <!-- 用于显示子表格张开按钮 -->
            <el-table-column prop="quantityType" label="" width="50px" align="center">
            </el-table-column>
            <el-table-column prop="goodsId"  :label='$i18n.t("common.ProductID")'  align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="goodsCode" :label='$i18n.t("common.SKU_EAN_Encoding")'  align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>SKU:{{ scope.row.sku }}</span><br />
                    <span>EAN:{{ scope.row.barCode }}</span><br />
                    <span>{{ $i18n.t("common.coding") }}:{{ scope.row.goodsCode }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="imageUrl" :label='$i18n.t("common.Productimage1")' align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <el-image style="height: 100px" v-if="scope.row.imageUrl"
                        :src="ossHost + scope.row.imageUrl" fit="fill"></el-image>
                </template>
            </el-table-column>

            <el-table-column prop="packageQuantity" :label='$i18n.t("common.PackingQuantity")' align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="title"  :label='$i18n.t("common.ProductName")' align="center" show-overflow-tooltip></el-table-column>

            <el-table-column  prop="treeList" :label=' $i18n.t("common.Category")' align="center"   show-overflow-tooltip>
                <template slot-scope="scope">
                    <div v-for="(item,index) in scope.row.treeList" :key="index">
                    <span v-for="(item2,index2) in item.parents" :key="index2">
                        <span>{{ item2.title }}></span>
                    </span>
                        <span>{{ item.title }}</span>
                    </div>
                </template>
            </el-table-column>

            <el-table-column prop="goodsSpecials" :label='$i18n.t("common.Countrieslisted")' align="center" show-overflow-tooltip>

                <template slot-scope="scope">
                    <span v-for="(s, index) in scope.row.goodsSpecials" :key="index">
                        <span v-if="s.shelf">{{
                            $dict.getDictValue('countrys', s.countryCode)
                        }}<br /></span>
                    </span>
                </template>
            </el-table-column>

            <el-table-column prop="goodsSpecials" :label='$i18n.t("common.inventory")' align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-for="(s, index) in scope.row.goodsSpecials" :key="index"
                            v-if="s.shelf&&s.ecomRemainQty">
                        {{ $dict.getDictValue('countrys', s.countryCode) }}:{{ s.ecomRemainQty }}<br/>
                    </span>
                </template>
            </el-table-column>

            <el-table-column prop="goodsSpecials" :label="$t('common.ProductPromotion')" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-if="isShowGoodsPromotion(scope.row.promotionRuleCountrys, 'NEW')">
                        <span style="font-weight:bold;text-align: left;display: block;">{{ $t('common.NewUserPromotion') }}<br/></span>
                        <span v-for="(promotion, index) in scope.row.promotionRuleCountrys" :key="index" v-if="promotion.userType == 'NEW'">
                        {{ $dict.getDictValue('countrys', promotion.countryCode) }}:{{ getPromotionGoodsPrice(promotion, scope.row.prices) }}<br/>
                        </span>
                    </span>
                    <span v-if="isShowGoodsPromotion(scope.row.promotionRuleCountrys, 'OLD')">
                        <span style="font-weight:bold;text-align: left;display: block;">{{ $t('common.RegularUserPromotion') }}<br/></span>
                        <span v-for="(promotion, index) in scope.row.promotionRuleCountrys" :key="index" v-if="promotion.userType == 'OLD'">
                        {{ $dict.getDictValue('countrys', promotion.countryCode) }}:{{ getPromotionGoodsPrice(promotion, scope.row.prices) }}<br/>
                        </span>
                    </span>
                    <span v-if="!scope.row.promotionRuleCountrys || scope.row.promotionRuleCountrys.length == 0">{{ $i18n.t("common.ThereAreCurrentlyNoProductPromotions") }}</span>
                </template>
            </el-table-column>



           
            <el-table-column prop="prices" :label='$i18n.t("common.fixedprice")' align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-for="(s, index) in scope.row.prices" :key="index">
                        {{ $dict.getDictValue('countrys', s.countryCode) }}:<span v-if="s.resultPrice">{{
                            s.resultPrice.toFixed(2)
                        }}</span><br />
                    </span>
                </template>
            </el-table-column>

           
            <el-table-column prop="shelf" :label='$i18n.t("common.state")' align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-if="scope.row.shelf == true" style="color: #46a8a0">{{ $i18n.t("common.Listed") }}</span>
                    <span v-else>{{ $i18n.t("common.Notlisted") }}</span>
                </template>
            </el-table-column>

            <el-table-column   :label='$i18n.t("common.operation")' align="center"  >
                <template slot-scope="scope">
                    <el-button type="text" size="small" @click="handleSelectGoods(scope.row)" class="table-inner-button">{{ $i18n.t("common.Select") }}</el-button>
                </template>
            </el-table-column>

        </el-table>
                
        <div class="block">
            <el-pagination @current-change="handleCurrentChange" :current-page="listQuery.page"
                :page-size="listQuery.size" layout="total, prev, pager, next, jumper" :total="pageData.total">
            </el-pagination>                       
        </div>
           
    </div>
</template>
<script>

import {getPromotionGoodsPrice, isShowGoodsPromotion} from "@/utils/promotion";
import dictionaryUtil from '@/utils/dictionaryUtil';

export default {
    components: {
    },
    props: {
        defaultDictionary: {
            type: Object,
            default: () => {
                return {};
            }
        }
    },
    data() {
        return { 
            dictionary: JSON.parse(JSON.stringify(this.defaultDictionary)),
            defaultListQuery: {  
                // preSale: 'true',
                effectShelf: null,
                shelfCountryCode: null,
                idFuzzy: '',
                page: 1,
                size: 10 
            }, 
            listQuery: {                
                // preSale: 'true',
                effectShelf: null,
                shelfCountryCode: null,
                idFuzzy: '',
                page: 1,
                size: 10        
            },
            pageData: {
                list: []
            },
            ossHost: process.env.VUE_APP_OSS_Head,

        };
    },
    mounted() {
        this.handleSearchList();
    },
    methods: {
        
        handleSearchList( ) {
            this.listQuery = Object.assign({}, this.defaultListQuery);
            this.loadlist();
        },
        // 
        handleCurrentChange(val) {
            this.listQuery.page = val;
            this.loadlist();
        },
        // 重置
        cleanQuery() {
            this.listQuery = Object.assign({}, this.defaultListQuery);
            this.loadlist();
        },
         // 商品查询
        loadlist(){
            let temp = JSON.stringify(this.listQuery, (key, value) => {
                if (value !== null && value !== '') {
                    return value;
                }
            });
            let listQueryData = JSON.parse(temp);
            this.$store.dispatch('postGoodsSearch', listQueryData).then((res) => {
                this.pageData = res;
                //过滤租户国家
                this.pageData.list = this.filterTenantCountry(this.pageData.list);
            });
        },

        //过滤租户国家
        filterTenantCountry(list) {
            // console.log('filterTenantCountry',list,list && list.length > 0)
            if (list && list.length > 0) {
                let tenantCountrys = dictionaryUtil.getDict('countrys').map((country) => country.code);
                list.map((item) => {
                    let goodsSpecials = item.goodsSpecials;
                    let prices = item.prices;
                    item.goodsSpecials = goodsSpecials.filter((goodsSpecials) => tenantCountrys.includes(goodsSpecials.countryCode));
                    item.prices = prices.filter((prices) => tenantCountrys.includes(prices.countryCode));
                });
                return list;
            }  
        },

        //选择商品
        handleSelectGoods(row) {  
            this.$emit('handleSelectGoods',JSON.parse(JSON.stringify(row)));
        },

        isShowGoodsPromotion,
        //获取促销价格
        getPromotionGoodsPrice,
    }
};
</script>
<style scoped>
 
 

.dialog-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    /* margin-top: 30px; */
}
  
  
 
 
</style>
