<template>
    <el-image
            v-if="imgUrl && imgUrl.length > 0"
            style="width: 100px; height: 100px;"
            :src="imgUrl.startsWith(ossHost) ? imgUrl + parameter : ossHost + imgUrl + parameter"
            :preview-src-list="getFullUrl()"
            fit="fill"
    ></el-image>
</template>

<script>
    export default {
        props: {
            imgUrl: {
                type: String,
                default: ''
            },
            imgUrls: {
                type: Array,
                default:  () => {
                    return []
                }
            }
        },

        data() {
            return {
                ossHost: process.env.VUE_APP_OSS_Head,
                parameter: '?x-oss-process=image/auto-orient,1/resize,m_fixed,h_200,w_200/format,webp'
            };
        },
        methods: {

            getFullUrl() {
                if (this.imgUrls && this.imgUrls.length > 0) {
                    return this.imgUrls.map((value) => {
                        return this.ossHost + value;
                    });
                }
                return [];
            }

        }
    };
</script>

<style></style>
